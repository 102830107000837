import React, { useEffect, useState } from 'react';
// Structure imports
import Layout from '@components/structure/Layout';
import Seo from '@components/utility/SEO';
import { KfButton, KfAccordion, KfAccordionItem, isMobile, debounce } from '@klickinc/kf-react-components';
import GTM from '@src/utils/GTM.js';
import Cta from '@components/content/Cta';
import Image from '@components/utility/Image';
import Hero from '@components/content/hero/Hero';
import Loadable from '@loadable/component';

// styles
import './styles.scss';

const Chart3 = Loadable(() => import('@components/content/chart/Chart3'));
const Chart4 = Loadable(() => import('@components/content/chart/Chart4'));
const WhizzbangLine = Loadable(() => import('@components/content/WhizzbangLine'));
const InViewport = Loadable(() => import('@components/utility/InViewport'));

const RealWorldData = () => {
	const [mobile, setMobile] = useState(false);
	const mobileHandler = () => {
		setMobile(isMobile());
	};

	const closeAccordion = (id) => {
		const el = document.querySelector(`#${id}`);
		if (el) {
			const trigger = el.querySelector('.kf-accordion__trigger button');
			if (trigger) trigger.click();
		}
	};

	const handleToggle = (e) => {
		const trigger = e.target;
		const expanded = trigger.getAttribute('aria-expanded') === 'true';
		const parent = trigger.closest('.kf-accordion');
		if (parent) {
			const id = parent.id.replace('acc-', '').replace('-1', '').replace('-2', '').replace('-', ' ');
			const label = `See ${id} summary`;
			GTM.accordionToggle(expanded, label);
		}

		if (expanded) {
			trigger.scrollIntoView();
		}
	};

	useEffect(() => {
		const mobileCheck = debounce(mobileHandler, 18);
		mobileCheck();
		if (typeof window !== 'undefined') {
			document.addEventListener('resize', mobileCheck);
			const triggers = document.querySelectorAll('.kf-accordion__trigger button');
			triggers.forEach(trigger => {
				trigger.addEventListener('click', (e) => handleToggle(e, trigger));
			});
			document.documentElement.classList.add('overflow-x-hidden');
		}
		return () => {
			document.removeEventListener('resize', mobileCheck);
			const triggers = document.querySelectorAll('.kf-accordion__trigger button');
			triggers.forEach(trigger => {
				trigger.removeEventListener('click', (e) => handleToggle(e, trigger));
			});
			document.documentElement.classList.remove('overflow-x-hidden');
		};
    },[])

	return (
		<Layout>
			<Seo title='Efficacy: Real-World Data | EMFLAZA® (deflazacort)' description='View real-world data about EMFLAZA® (deflazacort) and prednisone in treating DMD. View full Prescribing Information & Important Safety Information.' />
			<div className="md:container 2xl:mx-auto page--real-world-data xl:pr-48">
				<Hero
					heroImage='efficacy-real-world-data-hero.png'
					heroImageMobile='efficacy-real-world-data-hero-mobile.png'
					breadcrumbs={['Efficacy', 'Real-World Data']}
					title={
						<span>
							<span>2 REAL-WORLD STUDIES.</span>
							<br />
							<span className='inline-block pb-12 md:pb-20'></span>
							<span>2 YEARS+ PROLONGED AMBULATION <span className='lowercase spanVs'>vs</span> PREDNISONE.
								<sup className='text-sm md:text-3xl'>1,2</sup>
							</span>
						</span>
					}
					addedClass='py-2'
				>
					<p>
						In 2 separate real-world studies, adolescents treated with deflazacort
						showed prolonged ambulation compared to those treated with prednisone.<sup className='text-xxs'>1,2</sup>
					</p>
				</Hero>

				<div className="max-w-4xl px-8 text-emflaza-blue-600 md:px-0">

					{/* SECTION 1 START */}
					<div className='my-9 lg:ml-24'>
						<h2 className='text-lg font-bold leading-5 text-center uppercase md:text-2xl text-emflaza-blue-400'>
							Median age at loss of ambulation
							<sup className='text-xxs md:text-xs'>1,2</sup>
						</h2>

						<Chart3 />
					</div>
					{/* SECTION 1 END */}

					{/* SECTION 2 START */}
					<div className='relative flex flex-wrap mt-20 md:gap-10'>
						<WhizzbangLine imageName='whizzbang-line-4.png' />
						<Image imageName='efficacy-real-world-data-image-2.png' addedClass='max-w-[84px]' alt="2015 Study"></Image>
						<div className='flex-1 min-w-[300px]'>
							<p className='my-5 md:my-0'>
								A natural history observational prospective study evaluating age at loss of independent
								ambulation (LoA) and side-effect profiles associated with different corticosteroid
								regimens in DMD. The study included 340 DMD participants, aged 2-28 years,
								who were enrolled in the Cooperative International Neuromuscular Research
								Group Duchenne Natural History Study (CINRG-DNHS). LoA was defined as continuous
								wheelchair use, confirmed by inability to walk 10 minutes unaided.
								Effects of prednisone or prednisolone (PRED)/deflazacort (DFZ), administration
								frequency, and dose were analyzed by time-varying Cox regression.
								Average dose of daily PRED administered while ambulatory (n=94) was 75% ± 17% of recommended, which was lower than daily DFZ (83% ± 15%, n=80).<sup>1</sup>
							</p>
							<KfAccordion addedId='acc-2015-study'>
								<KfAccordionItem expandLabel="See Study Summary +" collapseLabel="Close Study Summary -" addedId="kf-accordion__acc-2015-study" addedClass='accordion-1 mt-6'>
									<div className='acc-inner-content'>
										<h3 className='my-5 font-bold'>Bello 2015 Study Summary</h3>
										<h4 className='font-bold'>Prednisone/prednisolone and deflazacort regimens in CINRG Duchenne Natural<br/> History Study</h4>
										<p>
											A natural history observational prospective study evaluating age at
											LoA and side-effect profiles associated with different glucocorticoid corticosteroid
											regimens in DMD. The study included 340 DMD participants, aged 2-28 years,
											who were enrolled in the CINRG-DNHS.
											LoA was defined as continuous wheelchair use, confirmed by inability to
											walk 10 minutes unaided. Effects of prednisone or PRED/DFZ,
											administration frequency, and dose were analyzed by time-varying Cox regression.
											Side-effect frequencies were compared using the chi-square test. Average dose of daily
											PRED administered while ambulatory (n=94) was  75% ± 17% of recommended,
											which was lower than daily DFZ (83% ± 15%, n=80).
											<br/>
											Participants treated ≥1 year while ambulatory (n=252/340) showed
											a 3-year median delay in LoA. Fourteen different regimens were observed.
											Nondaily treatment was common for PRED (37%) and rare for DFZ (3%).
											DFZ was associated with later LoA than PRED (hazard ratio 0.294 ± 0.053 vs 0.490 ± 0.08; 2-year difference in median LoA with daily administration).
											Average dose was lower for daily PRED (0.56 mg/kg/day, 75% of recommended) than daily DFZ
											(0.75 mg/kg/day, 83% of recommended). DFZ showed higher frequencies of
											growth delay, Cushingoid appearance, and cataracts,
											but not weight gain. <br/>
											For comparisons of median LoA between glucocorticoid
											corticosteroid-treated and untreated participants, “glucocorticoid
											corticosteroid-treated” only included those patients who had been
											administered glucocorticoid corticosteroids for ≥1 year, starting
											1≥ year before LoA. Side-effect frequency was calculated in 277
											participants (86.2%) with any treatment duration while ambulatory.
											Weight gain (65%), Cushingoid appearance (55%), growth delay (37%),
											behavior changes (37%), low bone mass density and/or fracture (22%),
											cataracts (15%), and skin abnormalities (13%) were most frequently reported.
											Some frequencies might be underestimated because side effects were
											recorded for only the 3 most recent glucocorticoid corticosteroid
											regimens before study baseline. Weight gain frequency was similar
											for daily DFZ and daily PRED, but daily DFZ showed higher incidence
											of Cushingoid appearance (72% vs 50%), growth delay (60% vs 27%), and cataracts (29% vs 5%).
										</p>

										<h4 className='mt-4 font-bold'>Study Limitations</h4>
										<p>Based upon the study design and enrollment, the following limitations should be considered:</p>
										<ul className='bulleted-list'>
											<li>Steroid use was not randomized as might be in a controlled clinical trial</li>
											<li>Comparisons between DFZ and PRED are not included in the approved Prescribing Information for DFZ, as PRED is not an approved treatment for DMD</li>
											<li>The analyses included a comparison of multiple glucocorticoid agents and regimens (such as daily vs intermittent dosing)
											</li>
											<ul className="list-none ml-9">
												<p className="flex"><span className="pr-1">—</span> Fourteen distinct regimens of PRED or DFZ were observed</p>
												<p className="flex"><span className="pr-1">—</span> An in-depth analysis of specific doses or schedules was not feasible in a long-term, prospective, observational study</p>
											</ul>
											<li>A “historical” improvement in care was noted, showing a parallel increase in the frequency of glucocorticoid prescriptions for DMD and implementation of other standards of care such as physical therapy, management of joint contractures, and bone fracture prevention</li>
											<li>DFZ and PRED utilized in this study were from various manufacturers in and outside the United States</li>
											<li>Potential differences were noted in socioeconomic status of patients and caregivers—DFZ was not commercially available in the United States at the time of the study</li>
										</ul>


										<h4 className='mt-4 font-bold'>Financial Disclosures of Study Sponsors</h4>
										<p>This project was funded through the Italian Ministry of Education PhD grant awarded to L.B. for the 28th Cycle of the Doctorate School of Medical, Clinical, and Experimental Science at the University of Padova, Italy; and grants from the NIH (U54HD053177, R24HD050846, UL1RR031988, UL1RR024992, U54RR026139, G12RR003051, 1R01AR061875, RO1AR062380), the US Department of Education/NIDRR (H133B031118, H133B090001), the US Department of Defense (W81XWH-09-1-0592).</p><br />
										<p>Bello L, Gordish-Dressman H, Morgenroth LP, et al; CINRG <span className="whitespace-nowrap">Investigators. Prednisone/prednisolone</span> and deflazacort regimens in the CINRG Duchenne Natural History Study. <em>Neurology</em>. 2015;85(12):1048-1055.</p>
										<KfButton clickFn={() => closeAccordion('acc-2015-study')} addedClass='text-emflaza-orange-300 underline my-5'>Close Study Summary -</KfButton>
									</div>
								</KfAccordionItem>
							</KfAccordion>
						</div>
					</div>
					{/* SECTION 2 END */}

					{/* SECTION 3 START */}
					<div className='relative flex flex-wrap md:gap-10'>
						<Image imageName='efficacy-real-world-data-image-3.png' addedClass='max-w-[84px]' add alt="2020 Study"></Image>
						<div className='flex-1 min-w-[300px]'>
							<p className='my-5 md:my-0'>
								A single-center, retrospective cohort analysis of 435 boys with DMD at
								Cincinnati Children’s Hospital Medical Center (CCHMC) from 2004 to March 2017.
								Outcomes assessed included: age at clinical events, including loss of ambulation
								(defined as functional mobility scale {'>'}4), and scoliosis; ambulatory, pulmonary,
								and cardiac function; and weight, lean body mass, and bone health.
								Average age at steroid initiation was 6.4 years for patients taking prednisone and
								5.7 years for those taking deflazacort.<sup>2</sup>
							</p>
							<KfAccordion addedId='acc-2020-study'>
								<KfAccordionItem expandLabel="See Study Summary +" collapseLabel="Close Study Summary -" addedId="kf-accordion__acc-2020-study" addedClass='accordion-1 mt-6'>
									<div className='acc-inner-content'>
										<h3 className='my-5 font-bold'>Marden 2020 Study Summary</h3>
										<h4 className='font-bold'>Real-world outcomes of long-term prednisone and deflazacort use in patients with Duchenne muscular dystrophy: experience at a single, large care center</h4>
										<p>
											A retrospective study assessing outcomes among patients with DMD receiving
											deflazacort or prednisone in real-world practice. 435 DMD patients,
											aged 4 years and older, who attended the CCHMC from 2004
											to March 2017 were studied retrospectively using time-to-event and regression analyses.
											Loss of ambulation was identified as functional mobility scale (FMS) score &gt;4, which
											is indicative of patients’ full-time use of a wheelchair for mobility in DMD. Age at onset
											of scoliosis was defined as the first recorded clinical diagnosis of scoliosis in the patient’s
											medical record. Age at first occurrence of FMS {`>`}4 and first diagnosis of scoliosis were
											studied in time-to-event analyses, with age as the time variable, and censoring at the end
											of data availability. Kaplan-Meier curves and log-rank tests were calculated to compare these
											outcomes between steroid initiation groups. Cox proportional hazards analyses were used to estimate
											the association between steroid type and age at event while adjusting for age at steroid initiation,
											the calendar year during which the patient had initiated steroid use, and whether the patient had initiated
											steroids prior to or after their first clinic visit at CCHMC. The adjusted analyses thus accounted
											for differences in the duration of steroid exposure, potential temporal trends that might
											impact outcomes across groups, and potential differences in care received prior to CCHMC.
											Sensitivity analyses were conducted distinguishing between patients who received daily
											versus other regimens of prednisone and, separately, distinguishing those who maintained
											prednisone versus those who switched to deflazacort after initiation.
										</p>

										<p>
											Among n = 600 total boys in the CCHMC database, n = 559
											had their date of steroid initiation recorded and n = 500 had
											a steroid type recorded at some point during their follow-up history at CCHMC.
											A further <br/>n = 435 of these patients had at least one assessment of
											FMS or an assessment for scoliosis. The overall average follow-up included in
											the time-to-event analyses was 11.2 years in the FMS analyses and 11.9 years
											in the scoliosis analyses. The follow-up time was similar across prednisone-
											and deflazacort-initiated patients ({`<`}6 months difference in mean follow-up time).
											At first clinic visit at CCHMC with functional data recorded, deflazacort-initiated
											patients were on average younger, shorter and weighed less, compared with prednisone-initiated patients.
											The deflazacort-initiated group was also younger on average at steroid initiation
											compared with the prednisone-initiated group (5.7 vs 6.4 years, respectively).
											Across measures of ambulatory, pulmonary and cardiac function at the first clinic visit at CCHMC,
											deflazacort-treated patients tended to have better or similar function compared with prednisone-treated patients.
										</p>

										<h4 className='mt-4 font-bold'>Study Limitations</h4>
										<p>Steroid use was not randomized as might be in a controlled clinical trial. Therefore:</p>
										<ul className='bulleted-list'>
											<li>Differences in outcomes between steroid groups could be confounded by factors that differ between these groups</li>
											<li>It could also be hypothesized that differences in care received prior to CCHMC, including potential differences in steroid dosing or supportive care, could have impacted outcomes</li>
											<li>A higher proportion of patients initiating prednisone did so prior to CCHMC compared with those initiating deflazacort it is possible that unobserved differences are present</li>
											<li>Cost has been a factor for most families at CCHMC who opt for prednisone instead of out-of-pocket, imported deflazacort</li>
											<li>The impact of different dosing regimens is a plausible contributor to the differences in outcomes observed in the present study between patients receiving prednisone and deflazacort</li>
											<li>Ambulatory test results were not available from patients who were unable or unwilling to complete the assessments</li>
										</ul>

										<p className='pt-4'>
											This study was facilitated by cTAP with funding from PTC.
											cTAP is a pre-competitive coalition of academic clinicians,
											drug developers and patient foundations formed in 2015 to overcome challenges
											affecting clinical trials in DMD. cTAP has received sponsorship
											from Astellas Pharma (Mitobridge), BioMarin, Biophytis,
											Bristol-Myers Squibb, Catabasis, FibroGen, Inc.,
											Italfarmaco SpA, Marathon Pharmaceuticals, Pfizer, Inc., PTC Therapeutics,
											Roche, Sarepta Therapeutics, Shire plc, Solid Biosciences, Summit Plc.,
											Wave Life Sciences, Parent Project Muscular Dystrophy,
											Charley’s Fund and CureDuchenne, a founding patient advocacy partner
											and provider of initial seed funding to cTAP.
										</p>
										<p className='pt-4'>
											Marden JR, Freimark J, Yao Z, Signorovitch J, Tian C, Wong BL.
											Real-world outcomes of long-term prednisone and deflazacort use
											in patients with Duchenne muscular dystrophy: experience at a single,
											large care center. <span className='italic'>J Comp Eff Res</span>. 2020;9(3):177-189.
										</p>
										<KfButton clickFn={() => closeAccordion('acc-2020-study')} addedClass='text-emflaza-orange-300 underline my-5'>Close Study Summary -</KfButton>
									</div>
								</KfAccordionItem>
							</KfAccordion>
						</div>
					</div>
					{/* SECTION 3 END */}

					{/* SECTION 4 START */}
					<div className='relative section-5'>
						<div className="max-w-[240px] md:max-w-none absolute section-bg -top-24 md:-top-64 -left-8 -right-8 md:-left-40 2xl:-left-1/3 3xl:-left-2/4 3xl:-top-32 z-negative">
							<Image imageName='efficacy-real-world-data-image-4.png' imageNameMobile='efficacy-real-world-data-image-4-mobile.png' alt=""></Image>
						</div>
						<div className='inner-content md:min-h-[22vh] my-20 md:mt-40'>
							<h2 className='text-xl leading-6 pt-12 pb-0 md:text-4xl font-black pb-6 max-w-[150px] md:max-w-[18.75rem] uppercase text-emflaza-white-100 lg:pt-4'>
								Real-world outcomes. Real-life impact.
								<sup className='superscript'>2</sup>
							</h2>
						</div>
					</div>
					{/* SECTION 4 END */}

					{/* SECTION 5 START */}
					<div className='my-28 md:my-16 md:gap-10'>
						<div className='flex flex-wrap space-y-5 md:space-y-0 md:space-x-10 my-7 md:ml-32'>
							<div className='flex flex-col justify-end flex-1 max-w-[270px]'>
								<h2 className='mb-4 text-xl font-black uppercase text-emflaza-blue-600 md:text-4xl'>2020<br className='hidden md:inline'/> Long-Term <br/> Real-World Study</h2>
								<p>Scoliosis developed later in patients taking deflazacort than those taking prednisone.<sup>2</sup></p>
							</div>

							<div className='flex flex-col justify-between flex-1 min-w-[300px]'>
								<div className='relative mb-4'>
									<InViewport delay={500} disable={mobile} addedClass='inViewport--image'>
										<Image imageName='efficacy-real-world-data-image-5.png' imageNameMobile='efficacy-real-world-data-image-5-mobile.png' alt="2x more patients had delayed onset of scoliosis at the end of the study vs those taking prednisone"></Image>
										<span className='absolute top-3 left-1/4 patient-image-alt max-w-[100px]'>Not an <br /> actual patient.</span>
									</InViewport>
								</div>
								<p>7.9% of deflazacort-initiated patients and 17.9% of prednisone-initiated patients developed scoliosis by the end of the study.<sup>2</sup></p>
							</div>
						</div>

						<div className='flex flex-wrap my-20 md:my-0 md:mb-20 md:mt-10 md:gap-10'>
							<Image imageName='efficacy-real-world-data-image-3.png' addedClass='max-w-[84px]' alt="2020 Study"></Image>
							<div className='flex-1 min-w-[300px]'>
								<p className='my-5 md:my-0'>
									A single-center, retrospective cohort analysis of 435 boys with DMD at
									Cincinnati Children’s Hospital Medical Center (CCHMC) from 2004 to March 2017.
									Outcomes assessed included: age at clinical events, including loss of ambulation
									(defined as functional mobility scale {'>'}4), and scoliosis; ambulatory, pulmonary,
									and cardiac function; and weight, lean body mass, and bone health.
									Average age at steroid initiation was 6.4 years for patients taking prednisone and
									5.7 years for those taking deflazacort.<sup>2</sup>
								</p>
								<KfAccordion addedId='acc-2020-study-2'>
									<KfAccordionItem expandLabel="See Study Summary +" collapseLabel="Close Study Summary -" addedId="kf-accordion__acc-2020-study-2" addedClass='accordion-1 mt-6'>
										<div className='acc-inner-content'>
											<h3 className='my-5 font-bold'>Marden 2020 Study Summary</h3>
											<h4 className='font-bold'>Real-world outcomes of long-term prednisone and deflazacort use in patients with Duchenne muscular dystrophy: experience at a single, large care center</h4>
											<p>
												A retrospective study assessing outcomes among patients with DMD receiving
												deflazacort or prednisone in real-world practice. 435 DMD patients,
												aged 4 years and older, who attended the CCHMC from 2004
												to March 2017 were studied retrospectively using time-to-event and regression analyses.
												Loss of ambulation was identified as functional mobility scale (FMS) score {'>'}4, which
												is indicative of patients’ full-time use of a wheelchair for mobility in DMD. Age at onset
												of scoliosis was defined as the first recorded clinical diagnosis of scoliosis in the patient’s
												medical record. Age at first occurrence of FMS {`>`}4 and first diagnosis of scoliosis were
												studied in time-to-event analyses, with age as the time variable, and censoring at the end
												of data availability. Kaplan-Meier curves and log-rank tests were calculated to compare these
												outcomes between steroid initiation groups. Cox proportional hazards analyses were used to estimate
												the association between steroid type and age at event while adjusting for age at steroid initiation,
												the calendar year during which the patient had initiated steroid use, and whether the patient had initiated
												steroids prior to or after their first clinic visit at CCHMC. The adjusted analyses thus accounted
												for differences in the duration of steroid exposure, potential temporal trends that might
												impact outcomes across groups, and potential differences in care received prior to CCHMC.
												Sensitivity analyses were conducted distinguishing between patients who received daily
												versus other regimens of prednisone and, separately, distinguishing those who maintained
												prednisone versus those who switched to deflazacort after initiation.
											</p>

											<p>
												Among n = 600 total boys in the CCHMC database, n = 559
												had their date of steroid initiation recorded and n = 500 had
												a steroid type recorded at some point during their follow-up history at CCHMC.
												A further <br/>n = 435 of these patients had at least one assessment of
												FMS or an assessment for scoliosis. The overall average follow-up included in
												the time-to-event analyses was 11.2 years in the FMS analyses and 11.9 years
												in the scoliosis analyses. The follow-up time was similar across prednisone-
												and deflazacort-initiated patients ({`<`}6 months difference in mean follow-up time).
												At first clinic visit at CCHMC with functional data recorded, deflazacort-initiated
												patients were on average younger, shorter and weighed less, compared with prednisone-initiated patients.
												The deflazacort-initiated group was also younger on average at steroid initiation
												compared with the prednisone-initiated group (5.7 vs 6.4 years, respectively).
												Across measures of ambulatory, pulmonary and cardiac function at the first clinic visit at CCHMC,
												deflazacort-treated patients tended to have better or similar function compared with prednisone-treated patients.
											</p>

											<h4 className='mt-4 font-bold'>Study Limitations</h4>
											<p>Steroid use was not randomized as might be in a controlled clinical trial. Therefore:</p>
											<ul className='bulleted-list'>
												<li>Differences in outcomes between steroid groups could be confounded by factors that differ between these groups</li>
												<li>It could also be hypothesized that differences in care received prior to CCHMC, including potential differences in steroid dosing or supportive care, could have impacted outcomes</li>
												<li>A higher proportion of patients initiating prednisone did so prior to CCHMC compared with those initiating deflazacort it is possible that unobserved differences are present</li>
												<li>Cost has been a factor for most families at CCHMC who opt for prednisone instead of out-of-pocket, imported deflazacort</li>
												<li>The impact of different dosing regimens is a plausible contributor to the differences in outcomes observed in the present study between patients receiving prednisone and deflazacort</li>
												<li>Ambulatory test results were not available from patients who were unable or unwilling to complete the assessments</li>
											</ul>

											<p className='pt-4'>
												This study was facilitated by cTAP with funding from PTC.
												cTAP is a pre-competitive coalition of academic clinicians,
												drug developers and patient foundations formed in 2015 to overcome challenges
												affecting clinical trials in DMD. cTAP has received sponsorship
												from Astellas Pharma (Mitobridge), BioMarin, Biophytis,
												Bristol-Myers Squibb, Catabasis, FibroGen, Inc.,
												Italfarmaco SpA, Marathon Pharmaceuticals, Pfizer, Inc., PTC Therapeutics,
												Roche, Sarepta Therapeutics, Shire plc, Solid Biosciences, Summit Plc.,
												Wave Life Sciences, Parent Project Muscular Dystrophy,
												Charley’s Fund and CureDuchenne, a founding patient advocacy partner
												and provider of initial seed funding to cTAP.
											</p>
											<p className='pt-4'>
												Marden JR, Freimark J, Yao Z, Signorovitch J, Tian C, Wong BL.
												Real-world outcomes of long-term prednisone and deflazacort use
												in patients with Duchenne muscular dystrophy: experience at a single,
												large care center. <span className='italic'>J Comp Eff Res</span>. 2020;9(3):177-189.
											</p>
											<KfButton clickFn={() => closeAccordion('acc-2020-study-2')} addedClass='text-emflaza-orange-300 underline my-5'>Close Study Summary -</KfButton>
										</div>
									</KfAccordionItem>
								</KfAccordion>
							</div>
						</div>
					</div>
					{/* SECTION 5 END */}



					{/* SECTION 6 START */}
					<div className='relative section-6'>
						<div className="max-w-[300px] md:max-w-none absolute section-bg -top-11 md:-top-20 -left-8 -right-8 md:-left-20 2xl:-left-1/3 3xl:-left-2/4 3xl:-top-32 z-negative">
							<Image imageName='efficacy-real-world-data-image-6.png' imageNameMobile='efficacy-real-world-data-image-6-mobile.png' alt=""></Image>
						</div>
						<div className='inner-content min-h-[25vh] md:min-h-[22.5rem] my-20'>
							<h2 className='text-xl leading-6 md:text-4xl font-black pb-6 max-w-[200px] md:max-w-[21.875rem] uppercase text-emflaza-white-100'>
								Daily deflazacort delayed loss of ambulation longer<span className="lowercase"> vs </span>daily, weekend, and intermittent prednisone
								<sup className='superscript'>1</sup>
							</h2>
						</div>
					</div>
					{/* SECTION 6 END */}


					{/* SECTION 7 START */}
					<div className='md:ml-12'>
						<h2 className='text-lg font-bold text-center uppercase md:text-2xl text-emflaza-blue-400 max-w-[500px] mb-7 mx-auto leading-5'>
							Age at Loss of Ambulation with <br className='hidden md:inline' /> deflazacort<span className="lowercase"> vs </span> prednisone
							<sup className='text-xxs md:text-xs'>1</sup>
						</h2>

						<Chart4 />
					</div>
					{/* SECTION 7 END */}

					{/* SECTION 8 START */}
					<div className='relative flex flex-wrap mt-20 md:gap-10'>
						<WhizzbangLine imageName='whizzbang-line-4.png' />
						<Image imageName='efficacy-real-world-data-image-2.png' addedClass='max-w-[84px]' alt="2015 Study"/>
						<div className='flex-1 min-w-[300px]'>
							<p className='my-5 md:my-0'>
								A natural history observational prospective study evaluating age at loss of independent
								ambulation (LoA) and side-effect profiles associated with different corticosteroid
								regimens in DMD. The study included 340 DMD participants, aged 2-28 years,
								who were enrolled in the Cooperative International Neuromuscular Research
								Group Duchenne Natural History Study (CINRG-DNHS). LoA was defined as continuous
								wheelchair use, confirmed by inability to walk 10 minutes unaided.
								Effects of prednisone or prednisolone (PRED)/deflazacort (DFZ), administration
								frequency, and dose were analyzed by time-varying Cox regression.
								Average dose of daily PRED administered while ambulatory (n=94) was 75% ± 17% of
								recommended, which was lower than daily DFZ (83% ± 15%, n=80).<sup>1</sup>
							</p>
							<KfAccordion addedId='acc-2015-study-2'>
								<KfAccordionItem expandLabel="See Study Summary +" collapseLabel="Close Study Summary -" addedId="kf-accordion__acc-2015-study-2" addedClass='accordion-1 mt-6'>
									<div className='acc-inner-content'>
										<h3 className='my-5 font-bold'>Bello 2015 Study Summary</h3>
										<h4 className='font-bold'>Prednisone/prednisolone and deflazacort regimens in CINRG Duchenne Natural<br/> History Study</h4>
										<p>
											A natural history observational prospective study evaluating age at LoA
											and side-effect profiles associated with different glucocorticoid
											corticosteroid regimens in DMD. The study included 340 DMD participants,
											aged 2-28 years, who were enrolled in the CINRG-DNHS.
											LoA was defined as continuous wheelchair use, confirmed by inability
											to walk 10 minutes unaided. Effects of prednisone or PRED/DFZ, administration
											frequency, and dose were analyzed by time-varying Cox regression.
											Side-effect frequencies were compared using the chi-square test.
											Average dose of daily PRED administered while ambulatory (n=94) was 75% ±
											17% of recommended, which was lower than daily DFZ (83% ± 15%, n=80).
										</p>

										<p>
											Participants treated ≥1 year while ambulatory (n=252/340)
											showed a 3-year median delay in LoA.
											Fourteen different regimens were observed. Nondaily
											treatment was common for PRED (37%) and rare for DFZ (3%).
											DFZ was associated with later LoA than PRED (hazard ratio
											0.294 ± 0.053 vs 0.490 ± 0.08, 2-year difference
											in median LoA with daily administration).
											Average dose was lower for daily PRED (0.56 mg/kg/day, 75% of recommended)
											than daily DFZ (0.75 mg/kg/day, 83% of recommended).
											DFZ showed higher frequencies of growth delay, Cushingoid
											appearance, and cataracts, but not weight gain.
										</p>

										<p>
											For comparisons of median LoA between glucocorticoid
											corticosteroid-treated and untreated participants,
											“glucocorticoid corticosteroid-treated” only included
											those patients who had been administered glucocorticoid
											corticosteroids for ≥1 year, starting 1≥ year before LoA.
											Side-effect frequency was calculated in 277 participants
											(86.2%) with any treatment duration while ambulatory.
											Weight gain (65%), Cushingoid appearance (55%), growth delay (37%),
											behavior changes (37%), low bone mass density and/or fracture (22%),
											cataracts (15%), and skin abnormalities (13%) were most frequently reported.
											Some frequencies might be underestimated because side effects were recorded
											for only the 3 most recent glucocorticoid corticosteroid regimens before
											study baseline. Weight gain frequency was similar for daily DFZ and daily
											PRED, but daily DFZ showed higher incidence of Cushingoid appearance
											(72% vs 50%), growth delay (60% vs 27%),
											and cataracts (29% vs 5%).
										</p>

										<h4 className='mt-4 font-bold'>Study Limitations</h4>
										<p>Based upon the study design and enrollment, the following limitations should be considered:</p>
										<ul className='bulleted-list'>
											<li>Steroid use was not randomized as might be in a controlled clinical trial</li>
											<li>Comparisons between DFZ and PRED are not included in the approved Prescribing Information for DFZ, as PRED is not an approved treatment for DMD</li>
											<li className='flex'>
												The analyses included a comparison of multiple glucocorticoid agents and
												regimens (such as daily vs intermittent dosing)
											</li>
											<ul className="list-none ml-9">
												<p className="flex"><span className="pr-1">—</span> Fourteen distinct regimens of PRED or DFZ were observed</p>
												<p className="flex"><span className="pr-1">—</span> An in-depth analysis of specific doses or schedules was not feasible in a long-term, prospective,<br/> observational, natural history study</p>
											</ul>
											<li>A “historical” improvement in care was noted, showing a parallel increase in the frequency of glucocorticoid prescriptions for DMD and implementation of other standards of care such as physical therapy, management of joint contractures, and bone fracture prevention</li>
											<li>DFZ and PRED utilized in this study were from various manufacturers in and outside the United States</li>
											<li>Potential differences were noted in socioeconomic status of patients and caregivers—DFZ was not commercially available in the United States at the time of the study</li>
										</ul>

										<div>
											<h4 className='mt-4 font-bold'>Financial Disclosures of Study Sponsors</h4>
											<p>
												This project was funded through the Italian Ministry of Education PhD grant awarded to L.B. for the 28th Cycle of the Doctorate School of Medical, Clinical, and Experimental Science at the University of Padova, Italy; and grants from the NIH (U54HD053177, R24HD050846, UL1RR031988, UL1RR024992, U54RR026139, G12RR003051, 1R01AR061875, RO1AR062380), the US Department of Education/NIDRR (H133B031118, H133B090001), the US Department of Defense (W81XWH-09-1-0592).
											</p>
											<br/>
											<p>
												Bello L, Gordish-Dressman H, Morgenroth LP, et al; CINRG <span className="whitespace-nowrap">Investigators. Prednisone/prednisolone</span> and deflazacort regimens in the CINRG Duchenne Natural History Study. <i>Neurology</i>. 2015;85(12):1048-1055.
											</p>

											<KfButton clickFn={() => closeAccordion('acc-2015-study-2')} addedClass='text-emflaza-orange-300 underline my-5'>Close Study Summary -</KfButton>
										</div>
								</div>

								</KfAccordionItem>
							</KfAccordion>
						</div>
					</div>
					{/* SECTION 8 END */}


					<div className='mt-5'>
						<h4 className='font-bold'>Study Limitations</h4>
						<p>
							Comparisons between deflazacort and prednisone are not included in
							the Prescribing Information for deflazacort because prednisone is not
							an approved treatment for DMD.
						</p>
					</div>

					<div className="flex flex-wrap mt-10 mb-8 space-y-5 md:space-y-0 md:space-x-5">
						<Cta ctaUrl='/getting-started/prescription-start-form' iconUrl={'cta-prescription.png'} ctaText={<span>Prescription <br /> Start Form</span>} addedClass='cta flex-1' iconAddedClass='' />
						<Cta ctaUrl='/efficacy/long-term-outcomes' iconUrl={'cta-icon-clock.png'} ctaText='Long-Term Data' addedClass='cta flex-1' iconAddedClass='max-w-[72px]'/>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default RealWorldData;
